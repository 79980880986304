.link {
  text-decoration: none;
  color: inherit;
  opacity: 0.6;
  transition: 0.2s ease-in-out;

  &:hover {
    opacity: 1;
  }
}
