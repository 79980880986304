.event-slider {
  position: relative;
  overflow: hidden;

  &__wrapper {
    display: flex;
  }

  &__slide {
    flex-shrink: 0;
  }

  &__pagination {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px;
  }

  &__bullet {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #FB584F;
    transition: 0.2s ease-in-out;
  }

  &__bullet_active {
    background-color: #F4E4C9;
  }

  &__slide {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 560px;

    @media (max-width: 479px) {
      min-height: 320px;
    }
  }

  &__pic-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__pic-wrapper::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__pic {
    display: block;
    width: 100%;
  }

  &__title {
    position: relative;
    z-index: 1;
    margin: auto 0 0;
    padding: 0 40px 56px;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    color: #F4E4C9;
  }
}
