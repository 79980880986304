.schedule {
  &__items {
    display: flex;
    flex-direction: column;
    margin-top: -32px;

    @media (max-width: 767px) {
      margin-top: -24px;
    }
  }

  &__item {
    margin-top: 32px;

    @media (max-width: 767px) {
      margin-top: 24px;
    }
  }

  &__item_hidden {
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(16px);
    transition: 0.2s ease-in-out;
  }

  &__item_shown {
    position: static;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }

  &__item-title {
    margin: 0 0 16px;
    font-size: 24px;
    font-weight: 600;

    @media (max-width: 767px) {
      margin: 0 0 12px;
    }
  }

  &__item-descr {
    margin: 0;
  }

  &__buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 32px;
  }
}
