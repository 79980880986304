.contacts {
  &__feedback {
    margin-bottom: 56px;
  }

  &__feedback {
    display: flex;
    flex-wrap: wrap;
  }

  &__info {
    flex-basis: 25%;
    box-sizing: border-box;
    padding-right: 40px;
    border: solid #0f1216;
    border-width: 0 1px 0 0;

    @media (max-width: 767px) {
      flex-basis: 100%;
      padding: 0 0 40px;
      border-width: 0 0 1px 0;
    }
  }

  &__info-items {
    display: flex;
    flex-direction: column;
    margin-top: -24px;

    @media (max-width: 767px) {
      margin-top: -20px;
    }
  }

  &__info-item {
    margin-top: 24px;

    @media (max-width: 767px) {
      margin-top: 20px;
    }
  }

  &__info-title {
    margin: 0 0 12px;
    font-size: 20px;
    text-transform: uppercase;
    opacity: 0.8;
  }

  &__address {
    margin: 0 0 8px;
  }

  &__schedule {
    font-size: 14px;
    opacity: 0.8;
  }

  &__link {
    display: inline-block;
    margin: 0 0 8px;
    text-decoration: none;
    color: #0f1216;
    transition: 0.2s ease-in-out;

    &:hover {
      opacity: 0.6;
    }
  }

  &__form {
    flex-basis: 75%;
    box-sizing: border-box;
    padding-left: 40px;

    @media (max-width: 767px) {
      flex-basis: 100%;
      padding: 40px 0 0;
    }
  }
}
