.collapse {
  &__toggler {
    cursor: pointer;
  }

  &__body {
    position: absolute;
    top: 100%;
    left: 0;
    opacity: 0;
    pointer-events: none;
    transform: translateY(24px);
    transition: 0.2s ease-in-out;
  }

  &__body_shown {
    position: static;
    opacity: 1;
    pointer-events: all;
    transform: translateY(0);
  }
}
