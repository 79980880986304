.page {
  margin: 0;
  color: #0f1216;

  &__section {
    margin: 64px 0 72px;

    @media (max-width: 991px) {
      margin: 60px 0 68px;
    }

    @media (max-width: 767px) {
      margin: 56px 0 64px;
    }
  }

  &__section_covered {
    margin: 0;
  }

  &__section_b-indent_zero {
    margin-bottom: 0;
  }

  &__section-title {
    text-transform: uppercase;
    margin: 0 0 56px;
    font-size: 40px;
    font-weight: 700;
    text-align: center;

    @media (max-width: 991px) {
      margin: 0 0 48px;
      font-size: 32px;
    }

    @media (max-width: 767px) {
      margin: 0 0 40px;
      font-size: 28px;
      font-weight: 600;
    }
  }

  &__section-title_red {
    color: #FB584F;
  }

  &__section-title_white {
    color: #F4E4C9;
  }

  &__container {
    max-width: 1200px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 0 auto;
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);

    pointer-events: none;
    opacity: 0;
    transition: 0.2s ease-in-out;
  }

  &__overlay_shown {
    pointer-events: all;
    opacity: 1;
  }
}

.page_locked {
  height: 100vh;
  overflow: hidden;
}
