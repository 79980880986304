.header {
  padding: 16px 0 24px;
  background-color: #0f1216;

  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1240px;
    box-sizing: border-box;
    padding: 0 12px;
    margin: 0 auto;
  }

  &__nav {
    display: flex;
  }

  &__nav-link {
    padding: 8px 16px;
    font-size: 18px;
    color: #fff;
    text-decoration: none;
    transition: 0.2s ease-in-out;
  }

  &__nav-link:first-child {
    margin-left: 0;
  }

  &__nav-link:hover {
    opacity: 0.6;
    border-color: transparent;
  }
}
