.anons-card {
  display: flex;
  flex-wrap: wrap;
  background-color: #F2F2F2;

  @media (max-width: 767px) {
    flex-basis: 100%;
    order: 1;
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-basis: 50%;
    box-sizing: border-box;
    padding: 16px;

    @media (max-width: 767px) {
      flex-basis: 100%;
      order: 1;
    }
  }

  &__chevron {
    position: absolute;
    top: 16px;
    width: 24px;
    height: 24px;
    background-repeat: no-repeat;
    background-position: 0 center;
    background-size: 24px;

    @media (max-width: 767px) {
      display: none;
    }
  }

  &__chevron_left {
    left: -14px;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='33' viewBox='0 0 18 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.0721436 16.636L17.6109 0.535912L17.6109 32.736L0.0721436 16.636Z' fill='%23F2F2F2'/%3E%3C/svg%3E%0A");
  }

  &__chevron_right {
    right: -14px;
    background-image: url("data:image/svg+xml,%3Csvg width='19' height='33' viewBox='0 0 19 33' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.4571 16.636L0.918377 32.736L0.918377 0.535912L18.4571 16.636Z' fill='%23F2F2F2'/%3E%3C/svg%3E%0A");
  }

  &__type {
    margin: 0 0 4px;
    text-transform: uppercase;
    font-weight: 600;
    color: #FB584F;
  }

  &__title {
    margin: 0 0 8px;
    text-transform: uppercase;
    font-weight: 600;
  }

  &__descr {
    margin: 0 0 12px;
  }

  &__button-more {
    margin: auto 0 0;
    padding: 0;
    border: none;
    background: none;
    text-transform: lowercase;
    font-family: inherit;
    font-size: 16px;
    font-weight: 600;
    color: #FB584F;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  &__button-more::after {
    content: '';
    display: inline-block;
    width: 8px;
    height: 10px;
    margin-left: 4px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 20px;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7071 6.29297L9.29306 7.70697L13.5861 12L9.29306 16.293L10.7071 17.707L16.4141 12L10.7071 6.29297Z' fill='%23FB584F'/%3E%3C/svg%3E%0A");
  }

  &__button-more:hover {
    opacity: 0.6;
  }

  &__pic-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-basis: 50%;
    height: 280px;
    overflow: hidden;

    @media (max-width: 767px) {
      flex-basis: 100%;
    }

    @media (max-width: 479px) {
      height: 160px;
    }
  }

  &__pic {
    display: block;
    width: 100%;
  }
}
