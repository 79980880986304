.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 8px 20px;
  border: 1px solid transparent;
  background-color: transparent;
  font-family: inherit;
  text-transform: uppercase;
  font-size: 20px;
  cursor: pointer;
  transition: 0.2s ease-in-out;
}

.button_compact {
  width: auto;
}

.button_default {
  background-color: #FB584F;
  color: #fff;

  &:hover {
    opacity: 0.6;
  }
}
