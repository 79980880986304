.textarea {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 8px;
  }

  &__label_error {
    color: #d1241e;
  }

  &__input {
    border: 1px solid #adbdc799;
    padding: 8px;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    outline: none;
    resize: none;
  }
}
