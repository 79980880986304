.logo {
  display: block;
  min-height: 48px;
  min-width: 400px;
  background-repeat: no-repeat;
  background-position: 0 center;
  background-size: auto 48px;
  background-image: url('../../media/logo.svg');
  transition: 0.2s ease-in-out;
  
  @media (max-width: 767px) {
    min-width: 200px;
    background-size: auto 32px;
    background-image: url('../../media/logo-mob.svg');
  }
}

.logo:hover {
  opacity: 0.6;
}
