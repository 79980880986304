.cover {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &__container {
    padding: 64px 0 72px;

    @media (max-width: 991px) {
      padding: 60px 0 68px;
    }
  
    @media (max-width: 767px) {
      padding: 56px 0 64px;
    }
  }
}

.cover_events {
  background-image: url(../../media/covers/cover-events.png);
}

.cover_anonsi {
  background-image: url(../../media/covers/cover-anonsi.svg);
}

.cover_schedule {
  background-color: #F4E4C9;
}

.cover_faq {
  background-color: #fff;
}

.cover_afisha {
  background-image: url(../../media/covers/cover-afisha.svg);
}

.cover_map {
  background-image: url(../../media/covers/cover-map.svg);
}
