.events-calendar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__event {
    flex-basis: 20%;

    @media (max-width: 991px) {
      flex-basis: 33.333%;
    }

    @media (max-width: 767px) {
      flex-basis: 50%;
    }

    @media (max-width: 479px) {
      flex-basis: 100%;
    }
  }
}
