.events-tile {
  display: flex;
  flex-wrap: wrap;

  &__cards {
    width: calc(67.777% - 16px);
    display: flex;
    flex-wrap: wrap;

    @media (max-width: 991px) {
      width: 100%;
    }

    @media (max-width: 767px) {
      width: calc(100% + 16px);
      margin-left: -16px;
      margin-top: -16px;
    }

    @media (max-width: 479px) {
      width: 100%;
      margin-left: 0;
      margin-top: -16px;
    }
  }

  &__card {
    width: 50%;

    @media (max-width: 767px) {
      width: calc(50% - 16px);
      margin-left: 16px;
      margin-top: 16px;
    }

    @media (max-width: 479px) {
      width: 100%;
      margin-left: 0;
      margin-top: 16px;
    }
  }

  &__card_large {
    width: calc(33.333% - 16px);
    margin-right: 16px;

    @media (max-width: 991px) {
      width: 100%;
      margin: 0 0 16px 0;
    }
  }
}
