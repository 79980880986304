.events-slider {
  position: relative;
  overflow: hidden;

  &__wrapper {
    display: flex;
  }

  &__slide {
    flex-shrink: 0;
  }

  &__pagination {
    margin: 12px 0 0;
    display: flex;
    justify-content: center;
  }

  &__bullet {
    width: 8px;
    height: 8px;
    margin: 0 2px;
    border-radius: 50%;
    background-color: #FB584F;
    transition: 0.2s ease-in-out;
  }

  &__bullet_active {
    opacity: 0.6;
  }

  &__nav {
    position: absolute;
    width: 24px;
    height: 24px;
    border: none;
    border-radius: 50%;
    border: 2px solid #FB584F;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 24px;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  &__nav:hover {
    opacity: 0.6;
  }

  &__nav_disabled {
    opacity: 0;
    pointer-events: none;
  }

  &__prev {
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.2929 17.707L14.7069 16.293L10.4139 12L14.7069 7.70703L13.2929 6.29303L7.58594 12L13.2929 17.707Z' fill='%23FB584F'/%3E%3C/svg%3E%0A");
  }

  &__next {
    top: 50%;
    right: 8px;
    transform: translateY(-50%);
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M10.7071 6.29297L9.29306 7.70697L13.5861 12L9.29306 16.293L10.7071 17.707L16.4141 12L10.7071 6.29297Z' fill='%23FB584F'/%3E%3C/svg%3E%0A");
  }

  &__card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 320px;
  }

  &__pic-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }

  &__pic-wrapper::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  &__pic {
    display: block;
    max-width: 100%;
  }

  &__content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
  }

  &__date {
    margin: 0 0 8px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 400;
    color: #FB584F;
  }

  &__title {
    margin: 0 0 16px;
    text-transform: uppercase;
    font-size: 24px;
    font-weight: 600;
    color: #F4E4C9;
  }

  &__button-more {
    display: inline-flex;
    align-items: center;
    margin: auto 0 0;
    padding: 8px 0;
    border: none;
    background-color: transparent;
    text-align: left;
    text-transform: uppercase;
    font-family: inherit;
    font-size: 18px;
    font-weight: 400;
    color: #FB584F;
    cursor: pointer;
    transition: 0.2s ease-in-out;
  }

  &__button-more:hover {
    opacity: 0.6;
  }
}
