.calendar-card {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  min-height: 320px;
  box-sizing: border-box;
  padding: 0 0 32px;

  &__pic-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &__pic {
    display: inline-block;
    width: 100%;
  }

  &__date {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    max-width: 140px;
    box-sizing: border-box;
    padding: 16px 16px 24px;
    background-color: #fff;
  }

  &__day {
    margin-bottom: 12px;
    padding-bottom: 8px;
    border-bottom: 2px solid #FB584F;
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: #FB584F;
  }

  &__title {
    margin-bottom: 12px;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    color: #FB584F;
  }

  &__time {
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    color: #FB584F;
  }
}
