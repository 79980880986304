.contacts-form {
  &__body {
    margin-bottom: 24px;
  }

  &__buttons {
    flex-basis: 50%;
  }

  &__notice {
    flex-basis: 50%;
  }

  &__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 24px -24px;

    &:last-child {
      margin-bottom: 0;
    }

    @media (max-width: 991px) {
      flex-basis: 100%;
      margin: -16px 0 24px 0;
    }
  }

  &__col {
    flex-basis: calc(50% - 24px);
    margin-left: 24px;

    @media (max-width: 991px) {
      flex-basis: 100%;
      margin: 16px 0 0 0;
    }
  }

  &__groups {
    display: flex;
    flex-direction: column;
    margin-top: -16px;
  }

  &__group {
    margin-top: 16px;
  }

  &__message {
    display: none;
    font-size: 20px;
  }

  &__message_success {
    color: #119c46;
  }

  &__message_error {
    color: #d1241e;
  }

  &__message_shown {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
