.select {
  position: relative;

  &__default-field {
    display: none;
  }

  &__label {
    display: block;
    margin: 0 0 8px;
  }

  &__toggler {
    display: flex;
    justify-content: space-between;
    border: 1px solid #adbdc799;
    padding: 8px;
    font-family: inherit;
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    cursor: pointer;

    &::after {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 16px;
      background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M21.531 6.969C21.6008 7.03867 21.6563 7.12143 21.6941 7.21255C21.7319 7.30367 21.7513 7.40135 21.7513 7.5C21.7513 7.59865 21.7319 7.69633 21.6941 7.78745C21.6563 7.87857 21.6008 7.96133 21.531 8.031L12.531 17.031C12.4613 17.1008 12.3786 17.1563 12.2875 17.1941C12.1963 17.2319 12.0987 17.2513 12 17.2513C11.9014 17.2513 11.8037 17.2319 11.7126 17.1941C11.6214 17.1563 11.5387 17.1008 11.469 17.031L2.469 8.031C2.32817 7.89017 2.24906 7.69916 2.24906 7.5C2.24906 7.30084 2.32817 7.10983 2.469 6.969C2.60983 6.82817 2.80084 6.74905 3 6.74905C3.19917 6.74905 3.39017 6.82817 3.531 6.969L12 15.4395L20.469 6.969C20.5387 6.89916 20.6214 6.84374 20.7126 6.80593C20.8037 6.76812 20.9014 6.74866 21 6.74866C21.0987 6.74866 21.1963 6.76812 21.2875 6.80593C21.3786 6.84374 21.4613 6.89916 21.531 6.969Z' fill='%230F1216'/%3E%3C/svg%3E%0A");
      transition: 0.2s ease-in-out;
    }
  }

  &__toggler_expanded {
    &::after {
      transform: rotate(-180deg);
    }
  }

  &__options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #adbdc799;
    background-color: #fff;
    transform: translateY(24px);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &__options_shown {
    z-index: 1;
    opacity: 1;
    transform: translateY(-1px);
    pointer-events: all;

    @media (max-width: 767px) {
      position: static;
    }
  }

  &__option {
    padding: 8px;
    transition: 0.2s ease-in-out;
    cursor: default;

    &:hover {
      background-color: #F2F2F2;
    }
  }

  &__option_selected {
    background-color: #adbdc799;
    // pointer-events: none;
  }
}
