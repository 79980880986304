.detail {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 3;
  display: none;
  height: 100vh;
  width: 70%;
  max-width: 1280px;
  overflow: auto;
  box-sizing: border-box;
  padding: 64px;
  background-color: #fff;

  @media (max-width: 767px) {
    width: 100%;
    padding: 24px 12px 32px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__title {
    margin: 0 0 40px;
    font-size: 32px;

    @media (max-width: 767px) {
      margin: 0 0 24px;
      font-size: 20px;
    }
  }

  &__button-closing {
    width: 40px;
    height: 40px;
    padding: 0;
    margin: 0;
    border: none;
    border-radius: 50%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-size: 32px;
    background-position: center;
    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M18.36 19.78L12 13.41L5.63997 19.78L4.21997 18.36L10.59 12L4.21997 5.63997L5.63997 4.21997L12 10.59L18.36 4.22997L19.77 5.63997L13.41 12L19.77 18.36L18.36 19.78Z' fill='%23FB584F'/%3E%3C/svg%3E%0A");
    cursor: pointer;
    transition: 0.2s ease-in-out;

    @media (max-width: 767px) {
      width: 24px;
      height: 24px;
      background-size: 24px;
    }
  }

  &__button-closing:hover {
    opacity: 0.6;
  }
}

.detail_shown {
  display: block;
}
